import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {graphql, useStaticQuery} from 'gatsby';
import Content from '../../layouts/Content/';

const ContentPageEn = () => {
  const data = useStaticQuery(graphql`
    query contentPageDataEn {
      image: file(relativePath: { eq: "content/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulCategorieDeGabarit(sort: {fields: name, order: ASC}, filter: {node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            name
            templates {
              name
              description {
                description
              }
              examples {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
      allContentfulSecteurs(filter: {handkeyContent: {eq: true}, node_locale: {eq: "en-CA"}}, sort: {fields: createdAt}) {
        edges {
          node {
            name
            permalink
            contentful_id
          }
        }
      }
    }
  `)

  return (
    <Layout 
    lang="en-CA" 
    switchLink='/contenu' 
    slider={true} 
    headerImage={data.image.childImageSharp} 
    title="Hundreds of customizable templates and turnkey content." 
    subTitle={"Choose templates according to your needs or adapt them to the colors of your company. Also, opt for turnkey content via our specialized channels."}
    anchorLink="#templates"
    anchorLinkText="Templates"
    anchorLink2="#turnkey-content"
    anchorLinkText2="Handkey content"
    >
      <Seo title="Digital Signage Design Templates Available for your Business"
           description="Choose display templates according to your needs or adapt them to your company colors. Wide range of digital signage designs to distribute your content optimally."
           lang="en-CA"/>
      <Content lang="en-CA" templateCategories={data.allContentfulCategorieDeGabarit} channels={data.allContentfulSecteurs} />
    </Layout>
  )
}

export default ContentPageEn
